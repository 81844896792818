import { useDispatch } from 'react-redux';
import { useEffect, useCallback } from 'react';

import { navigate } from 'gatsby';

import { showFlashMessage } from 'modules/ui/slice';

import { verifyEmail } from '../slice';

const AuthCallback = () => {
  const dispatch = useDispatch();

  const handleInvalidRequest = useCallback(() => {
    navigate('/');

    return dispatch(
      showFlashMessage({
        type: 'error',
        message: 'คำร้องขอไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง',
      })
    );
  }, [dispatch]);

  const handleMode = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const oobCode = params.get('oobCode');

    switch (params.get('mode')) {
      case 'verifyEmail':
        if (!oobCode) return handleInvalidRequest();

        return dispatch(verifyEmail(oobCode));
      case 'resetPassword':
        if (!oobCode) return handleInvalidRequest();

        return navigate('/auth/reset-password', { state: { oobCode } });
      default:
        return handleInvalidRequest();
    }
  }, [dispatch, handleInvalidRequest]);

  useEffect(() => {
    handleMode();
  }, [handleMode]);

  return null;
};

export default AuthCallback;
